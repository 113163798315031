import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Options are specified as `}<code><a parentName="p" {...{
          "href": "https://trailguide.net?opt1=val1&opt2=val3"
        }}>{`https://trailguide.net?opt1=val1&opt2=val3`}</a></code>{`
and so on. Please note the first option is specified by ? and the rest are
specified by &`}</p>
    <br />
    <br />
    <h4>{`Map options`}</h4>
    <br />
    <code>map</code>
    <br />
https://trailguide.net?map=norgeskart
    <ul>
      <li parentName="ul">{`This is the map base used for the tiles.
Valid options are trailguide, norgeskart, google, or photo.`}</li>
    </ul>
    <br />
    <code>cluster</code>
    <br />
    <code>https://trailguide.net?cluster=9</code>
    <ul>
      <li parentName="ul">{`Normally the trail heads starts clustering into "bubbles" on the map at zoom
level 12 when you zoom out. You can control this by specifying another number.`}</li>
    </ul>
    <br />
    <code>doubleClickZoom</code>
    <br />
    <code>https://trailguide.net?doubleClickZoom=false</code>
    <ul>
      <li parentName="ul">{`Turn of double-click zooming of the map`}</li>
    </ul>
    <br />
    <code>scrollWheelZoom</code>
    <br />
    <code>https://trailguide.net?scrollWheelZoom=false</code>
    <ul>
      <li parentName="ul">{`Turn of zooming by using the mouse scroll-wheel. This can be very useful
if using it as a plugin on a web page.`}</li>
    </ul>
    <br />
    <code>zoomControl</code>
    <br />
    <code>https://trailguide.net?zoomControl=false</code>
    <ul>
      <li parentName="ul">{`Turn off the +- scroll control box in the bottom right corner`}</li>
    </ul>
    <br />
    <code>dragging</code>
    <br />
    <code>https://trailguide.net?dragging=false</code>
    <ul>
      <li parentName="ul">{`Turn off dragging of the map`}</li>
    </ul>
    <br />
    <code>gray</code>
    <br />
    <code>https://trailguide.net?gray=true</code>
    <ul>
      <li parentName="ul">{`Set the map to grayscale`}</li>
    </ul>
    <br />
    <code>split</code>
    <br />
    <code>https://trailguide.net?split=false</code>
    <ul>
      <li parentName="ul">{`Disable the split map option`}</li>
    </ul>
    <br />
    <code>splitOn</code>
    <br />
    <code>https://trailguide.net?splitOn=true</code>
    <ul>
      <li parentName="ul">{`Show the split map`}</li>
    </ul>
    <br />
    <code>mapSelector</code>
    <br />
    <code>https://trailguide.net?mapSelector=false</code>
    <ul>
      <li parentName="ul">{`Disable the map selector`}</li>
    </ul>
    <br />
    <code>pos</code>
    <br />
    <code>https://trailguide.net?pos=10,60.7,8.9</code>
    <ul>
      <li parentName="ul">{`Place the map in the specified zoom level and position by giving it a zoom,
latitude, and longitude. The first number is the zoom level, the second is
the latitude, the last is the longitude.`}</li>
    </ul>
    <br />
    <code>trackWidth</code>
    <br />
    <code>https://trailguide.net?trackWidth=2</code>
    <ul>
      <li parentName="ul">{`Specify the relative width of the tracks. The default width is 1, and 2
is double the size of 1, and so on.`}</li>
    </ul>
    <br />
    <code>heads</code>
    <br />
    <code>https://trailguide.net?heads=false</code>
    <ul>
      <li parentName="ul">{`Hide the trail heads`}</li>
    </ul>
    <br />
    <code>tracks</code>
    <br />
    <code>https://trailguide.net?tracks=false</code>
    <ul>
      <li parentName="ul">{`Hide the tracks`}</li>
    </ul>
    <br />
    <code>trackZoomLevel</code>
    <br />
    <code>https://trailguide.net?trackZoomLevel=9</code>
    <ul>
      <li parentName="ul">{`Specify at what zoom level the tracks will appear on the map. The default
zoom-level is 11.`}</li>
    </ul>
    <br />
    <code>overlays</code>
    <br />
    <code>https://trailguide.net?overlays=mtbtracks|strava</code>
    <ul>
      <li parentName="ul">{`Show map overlays.`}</li>
    </ul>
    <br />
    <br />
    <h4>{`Application options`}</h4>
    <br />
    <code>searchOn</code>
    <br />
    <code>https://trailguide.net?searchOn=false</code>
    <ul>
      <li parentName="ul">{`Hide the search box.`}</li>
    </ul>
    <br />
    <code>add</code>
    <br />
    <code>https://trailguide.net?add=false</code>
    <ul>
      <li parentName="ul">{`Hide the 'Add-trail' button.`}</li>
    </ul>
    <br />
    <code>top</code>
    <br />
    <code>https://trailguide.net?top=false</code>
    <ul>
      <li parentName="ul">{`Hide the add and menu button.`}</li>
    </ul>
    <br />
    <code>hideList</code>
    <br />
    <code>https://trailguide.net?hideList=true</code>
    <ul>
      <li parentName="ul">{`Hide the list. The list can still be opened and closed by the user.`}</li>
    </ul>
    <br />
    <code>listOn</code>
    <br />
    <code>https://trailguide.net?listOn=false</code>
    <ul>
      <li parentName="ul">{`Disable the list.`}</li>
    </ul>
    <br />
    <code>position</code>
    <br />
    <code>https://trailguide.net?position=false</code>
    <ul>
      <li parentName="ul">{`Hide the location button.`}</li>
    </ul>
    <br />
    <code>lang</code>
    <br />
    <code>https://trailguide.net?lang=de</code>
    <ul>
      <li parentName="ul">{`Set the language to be used for the application by
specifying the ISO language code.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      